.takenjob {
    .header-job {
        display: flex;
        justify-content: space-between;

        .running {
            cursor: pointer;
            background-color: $primary;

            @keyframes blinking {
                0% {
                    opacity: 0.3
                }

                100% {
                    opacity: 1
                }
            }

            animation: blinking 1s infinite;

            border-radius: 20px;
            padding: 5px;
            display: flex;
            align-items: center;

            span {
                margin: 0px 10px;

                &.name {
                    color: white;
                }

                &.status {
                    background-color: $secondary;
                    color: $primary;
                    border-radius: 20px;
                    padding: 3px 10px;
                }
            }
        }
    }
}