.bill-default-add-koj {
    .koj-list {
        list-style-type: none;
        padding-left: 0px;

        li {
            background-color: $secondary;
            color: $primary;
            margin-bottom: 10px;
            padding: 5px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span {
                margin-right: auto;
                margin-left: 10px;
                width: 50%;
            }

            input {
                background-color: white;
                border-radius: 10px;
            }

            svg {
                justify-self: flex-end;
                color: gray;
                cursor: pointer;
                margin: 0px 10px
            }
        }
    }
}