.form-koj {
    .koj-list {
        list-style-type: none;
        padding-left: 0px;

        li {
            background-color: $secondary;
            color: $primary;
            margin-bottom: 10px;
            padding: 5px;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;

            div {
                display: flex;
            }

            svg {
                color: gray;
                cursor: pointer;
            }
        }
    }
}