.project {
    table.extended {

        td {
            padding-top: 5px;
        }
    }
}

.project-add-koj {
    .koj-list {
        list-style-type: none;
        padding-left: 0px;

        li {
            background-color: $secondary;
            color: $primary;
            margin-bottom: 10px;
            padding: 5px;
            border-radius: 10px;
            display: flex;
            align-items: center;

            span {
                width: 50%;
            }

            input {
                background-color: white;
                border-radius: 10px;
            }

            svg {
                color: gray;
                cursor: pointer;
                margin: 0px 20px
            }
        }
    }
}