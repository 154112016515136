.bill {
    .bg-primary {
        background-color: $primary;
        color: white;

        input {
            color: black;
            background-color: white;
            border-radius: 10px;
        }


        .button-container {
            display: flex;
            align-items: center;
        }
    }
}