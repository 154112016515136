$drawer-width: 230px;
$primary: #5a46f1;
$secondary: #EFECFE;
$bg: #E5E5E5;

@import "../containers/Containers.scss";
@import "../pages/project/project.scss";
@import "../pages/bill/bill.scss";
@import "../pages/master/employee/employee.scss";
@import "../pages/takenjob/Takenjob.scss";
@import "../pages/master/projectDefault/projectDefault.scss";
@import "../pages/master/billDefault/billDefault.scss";
@import "../pages/dashboard/dashboard.scss";

:root {
    .swal2-container {
        z-index: 3000;
    }

    font-size: small;
}

.MuiPopover-paper {
    box-shadow: 0px 0px 3px 3px rgba(144, 144, 144, 0.1) !important;
}

.custom-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;

    .custom-modal-content {
        width: 60vw;

        &.sm {
            width: 20vw;
        }

        .MuiCardHeader-root {
            span {
                font-size: 20px;
                font-weight: bold;
            }
        }

        .MuiCardContent-root {
            max-height: 90vh;
            overflow: auto;
        }

    }
}

button {
    text-transform: unset !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    white-space: nowrap;
}

.MuiPickersDay-root {
    margin: 0px 2px !important;
}

.custom-table-container {
    padding: 0px !important;

    .header {
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .inp {
            margin-left: 10px;
        }

        form {
            display: inline;
        }

        div {
            display: flex;
            align-items: center;
        }
    }

    .body {
        max-height: 80vh;
        overflow: auto;

        thead {
            position: sticky;
            top: 0;
            background-color: white;
            z-index: 1;
        }
    }

    table {
        width: 100%;
        border-spacing: 0px;

        thead {
            font-weight: bold;

            td {
                white-space: nowrap;
            }
        }

        tbody {
            td {
                b {
                    white-space: nowrap;
                }
            }

            tr.odd {
                background-color: #f2f2f2;
            }

            tr:hover {
                background-color: $secondary !important;
            }
        }

        td {
            padding: 15px 20px;
        }
    }
}

.custom-table-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px !important;

    .rows-per-page {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
            width: 300px;
        }

        .options {
            width: 75px;
        }
    }
}

.custom-form {
    .form-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span {
            font-size: 20px;
            font-weight: bold;
        }

        &.modal {
            position: sticky;
            top: 0px;
        }
    }

    .form-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
    }
}

.custom-loading {
    svg {
        width: 10vw;
        height: 10vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.clickable {
    cursor: pointer;
}

.status-progress {
    padding: 10px;
    border-radius: 10px;
    color: white;

    &.hold {
        background-color: rgb(212, 189, 63);
    }

    &.running {
        background-color: rgb(47, 4, 220);
    }

    &.complete {
        background-color: rgb(14, 178, 12);
    }
}

.fit-in-height {
    max-height: 90vh;
    overflow: auto;

    .active {
        background-color: rgba(0, 0, 0, 0.05);
    }
}